// Signup form
.signupFormPosition {
  top: 5% !important;
}

.signupFormColorcss {
  background-color: #b4b4b8;
  color: #070606; // Affects labels
  border-bottom-right-radius: 1rem;
  border-bottom-left-radius: 1rem;
}

.modalHeader {
  background-color: #b4b4b8;
}

.modal-header .btn-close {
  --bs-btn-close-color: #0d0d0e !important;
}

.modalBody {
  border-bottom-right-radius: 3rem;
}

.modal-backdrop {
  opacity: 0.6 !important;
}

.modalTitle {
  opacity: 1;
  color: #1f1e1e !important;
  font-family: "Roboto";
  font-size: 2rem !important;
}

.signupPhoneInput {
  color: black !important;
  font-family: "Roboto";
  font-size: 1rem !important;
  line-height: 20px !important;
  letter-spacing: -0.02em !important;
  border-radius: 5px !important;
  height: 64px !important;
  border: 1px solid white !important;

  .flag {
    scale: 1.5;
  }

  .selected-flag:hover {
    background-color: #e8f0fe !important;
  }

  .input:focus {
    color: #69e781;
  }

  .selected-flag {
    background-color: #e8f0fe !important;
  }

  /* Placeholder */
  ::placeholder {
    color: #a4a4a4 !important;
    font-size: 2rem !important;
  }

  /* dropdown arrow closed */
  .selected-flag .arrow {
    border-left: 5px solid transparent !important;
    border-right: 5px solid transparent !important;
    border-top: 5px solid #252525 !important;
  }

  /* dropdown arrow opened */
  .selected-flag .arrow.up {
    border-top: none !important;
    border-bottom: 5px solid #252525 !important;
  }

  .form-control {
    padding-left: 62px !important;
    height: 64px !important;
    font-size: 1.5rem !important;
    background-color: #e8f0fe !important;
    border: 0 #252525 !important;
    margin-right: 20;
    width: 100% !important; // Ocuppy all the space available
  }

  .country-list {
    position: absolute !important;
    left: 0 !important;
    right: 0 !important;
  }

  .country-list .country {
    padding: 16px 24px !important;
    min-height: 56px !important;
  }

  .country-list .country:hover {
    background: rgba(97, 115, 138, 0.477) !important;
  }

  .country-list .country.highlight {
    background: rgb(129, 169, 197) !important;
  }
}

/* Country dropdown flag */
.flag-dropdown {
  background: #e8f0fe !important;
  height: 64px !important;
  border: 0 !important;
}

.formInputMargin {
  margin-left: 50px;
  margin-right: 50px;
}

.formInputControl {
  background-color: #e8f0fe !important;
  color: black !important;
  margin-right: -40px; // Reach the button
  z-index: 0;
}

.formInputControl:focus {
  z-index: 0 !important; // Leave the zindex below the button
}

.formInputControl::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: white !important;
  opacity: 1; /* Firefox */
}

.formInputControl:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: white !important;
}

.formInputControl::-ms-input-placeholder {
  /* Microsoft Edge */
  color: white !important;
}

.modal-content {
  border-bottom-right-radius: 1.2rem !important;
  border-bottom-left-radius: 1.2rem !important;
}

.grayplusTextBold {
  color: rgb(93, 93, 93);
  font-weight: 500;
  font-style: normal;
  text-decoration: none; // remove underline
  font-family: "Roboto";
}

$btn-close-color: white;
