.location-buttons {
  margin-top: 10px;
  display: flex;
  align-items: center;

  .icon {
    margin-right: 10px;
  }

  .location-button {
    background-color: rgba(0, 0, 0, 0.5);
    border: none;
    border-radius: 5px;
    color: white;
    padding: 5px 10px;
    cursor: pointer;
    padding-left: 20px;
    padding-right: 20px;

    &:not(:last-child) {
      margin-right: 10px;
    }
  }

  .coordinates {
    margin-left: 10px;
    font-size: 14px;
    font-style: italic;
  }

  @media (max-width: 600px) {
    .coordinates {
      display: none;
    }
  }
}
