.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure it appears above other content */
  
  
  /* Modal content */
  .modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    max-width: 90%;
    width: 500px;
    text-align: center;
  }
  
  /* Modal actions */
  .modal-actions {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  /* Cancel button */
  .cancel-button {
    background-color: rgb(196, 222, 199);
    color: rgb(0, 0, 0);
    border: none;
    padding: 10px;
    border-radius: 5px;
    width: 150px;
    cursor: pointer;
  }
  
  /* Confirm button */
  .confirm-button {
    background-color: red;
    color: white;
    border: none;
    padding: 10px;
    border-radius: 5px;
    width: 150px;
    cursor: pointer;
  }
}