.product-block {
    max-width: 1200px; /* Limit the width */
    margin: 0 auto; /* Center the block horizontally */
    padding: 20px;
    border-bottom: 1px solid #ddd;
  }
  
  .block-title-left {
    font-style: italic;
    font-size: 1.5em;
    margin-bottom: 15px;
    text-align: left;
  }

  .block-title-right {
    font-style: italic;
    font-size: 1.5em;
    margin-bottom: 15px;
    text-align: right;
  }
  
  .block-content {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
  
  .image-column img {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
  }
  
  .text-column-aligned-left {
    flex: 1;
    text-align: left;
  }

  .text-column-aligned-left p {
    margin: 0 0 20px; /* Add spacing between paragraphs */
    line-height: 1.6; /* Improve readability with line height */
  }

  .text-column-aligned-right {
    flex: 1;
    text-align: right;
  }

  .text-column-aligned-right p {
    margin: 0 0 20px; /* Add spacing between paragraphs */
    line-height: 1.6; /* Improve readability with line height */
  }

  /* Mobile Styles */
@media (max-width: 600px) {
    .block-content {
      flex-direction: column; /* Stack the image and text */
      align-items: center;
    }
  
    .text-column {
      text-align: center; /* Center text for better readability */
    }
  }