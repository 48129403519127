// Login form
.loginFormPosition {
  top: 5% !important;
}

.loginFormColorcss {
  background-color: #302b2b;
  color: #fff;
  border-bottom-right-radius: 1rem;
  border-bottom-left-radius: 1rem;
}

.loginmodalHeader {
  background-color: #302b2b;
}

.loginmodalBody {
  border-bottom-right-radius: 3rem;
}

.loginmodal-backdrop {
  opacity: 0.6 !important;
}

.loginmodalTitle {
  opacity: 1;
  color: #fff !important;
  font-family: "Roboto";
  font-size: 2rem !important;
}

.loginformInputMargin {
  margin-left: 50px;
  margin-right: 50px;
}

.loginformInputControl {
  background-color: #302b2b !important;
  color: chocolate !important;
}

.loginformInputControl::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: white !important;
  opacity: 1; /* Firefox */
}

.loginformInputControl:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: white !important;
}

.loginformInputControl::-ms-input-placeholder {
  /* Microsoft Edge */
  color: white !important;
}

.loginmodal-content {
  border-bottom-right-radius: 1.2rem !important;
  border-bottom-left-radius: 1.2rem !important;
}

.logingrayTextBold {
  color: gray !important;
  font-weight: 500;
  font-style: normal;
  text-decoration: none; // remove underline
  font-family: "Roboto";
}

$btn-close-color: white;
