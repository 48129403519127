/* HomePage */
.phone-hash-search-container {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  min-width: 600px;
  max-width: clamp(500px, 80vw, 800px);
  margin-left: auto;
  margin-right: auto;
  
  .hashInput {
    font-family: monospace;
    margin-left: 20px;
    margin-right: 20px;
    width: 080px;
    flex-grow: 1 !important;
    font-size: 20px;
    height: 64px;
  }
  .searchButton {
    border-radius: 10px;
    border: 0px;
    background-color: slategray;
    height: 64px;
    width: 64px;

    .searchButtonIcon {
      font-size: 3rem;
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
    min-width: auto;
    max-width: 95%;

    .signupPhoneInput{
        width: 100% !important;
      }

    .hashInput {
      font-family: monospace;
      width: 100%;
      font-size: 20px;
      height: 50px;
      margin-left: 0px;
      margin-top: 5px;
      margin-bottom: 5px;
    }

    .searchButton {
      border-radius: 10px;
      border: 0px;
      background-color: slategray;
      height: 50px;
      width: 100%;

      .searchButtonIcon {
        font-size: 2rem;
      }
    }
  }
}
