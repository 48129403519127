// Add address
.selectedButton {
  font-weight: 500;
  font-style: bold;
  font-family: "Roboto";
  width: 150px;
  border-radius: 8px;
  border: 0.5px solid;
  padding: 5px;
  padding: 5px;
  background-color: #78bdee;
  color: #5f5a5a;
}

.unSelectedButton {
  font-weight: 500;
  font-style: bold;
  font-family: "Roboto";
  width: 150px;
  border-radius: 8px;
  border: 0.5px solid;
  padding: 5px;
  padding: 5px;
  background-color: #e3edf4;
  color: #5f5a5a;
}

.cancelButton {
  font-weight: 500;
  font-style: bold;
  font-family: "Roboto";
  width: 150px;
  border-radius: 8px;
  border: 0.5px solid;
  padding: 5px;
  padding: 5px;
  background-color: #fae4d8;
  color: #5f5a5a;
}

.selectedButtonLarge {
  font-weight: 500;
  font-style: bold;
  font-family: "Roboto";
  width: 300px;
  border-radius: 8px;
  border: 0.5px solid;
  padding: 5px;
  padding: 5px;
  background-color: #78bdee;
  color: #5f5a5a;
}

.unSelectedButtonLarge {
  font-weight: 500;
  font-style: bold;
  font-family: "Roboto";
  width: 300px;
  border-radius: 8px;
  border: 0.5px solid;
  padding: 5px;
  padding: 5px;
  background-color: #e3edf4;
  color: #5f5a5a;
}

.textInput {
  font-family: "Roboto";
  border-radius: 8px;
  border: 0.5px solid;
  padding: 5px;
  background-color: #e4e9ed;
  color: #171414;
  width: 100%;
}

.textInputReadOnly {
  font-family: "Roboto";
  border-radius: 8px;
  border: 0.5px solid;
  padding: 5px;
  background-color: #f5e1cb;
  color: #171414;
  width: 100%;
}

.validationMessage {
  font-family: "Roboto";
  font-style: italic;
  font-weight: bolder;
  position: absolute;
  top: 0px;
  right: 10px;
  color: #57353d;
  font-size: 12px;
  pointer-events: none;
  z-index: 1;
}

.centralForm {
  width: min(85vw, 700px);
}

.inputLabel {
  font-family: "Roboto";
  font-size: smaller;
  color: #171414;
}

.inputGroup {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  margin-bottom: 10px;
}

.twoHorizontalButtons {
  display: flex;
  width: min(85vw, 400px);
  justify-content: center;
  margin: 0 auto; /* Center the container horizontally */
}

.twoHorizontalButtons button {
  margin: 0 30px; /* Add some spacing between buttons */
}

.twoHorizontalButtonsLarge {
  display: flex;
  width: min(85vw, 700px);
  justify-content: center;
  margin: 0 auto; /* Center the container horizontally */
}

.twoHorizontalButtonsLarge button {
  margin: 0 30px; /* Add some spacing between buttons */
}

.threeHorizontalButtons {
  display: flex;
  width: min(85vw, 700px);
  justify-content: center;
  margin: 0 auto; /* Center the container horizontally */
}

.threeHorizontalButtons button {
  margin: 0 20px; /* Add some spacing between buttons */
}

/* All the .react-tel is nested inside the addAddressPhoneInput, afecting just this style */
.addAddressPhoneInput {
  color: black !important;
  font-family: "Roboto";
  display: flex !important;
  flex-direction: row !important;
  text-align: start !important;
  letter-spacing: -0.02em !important;
  border-radius: 8px !important;
  height: 50px !important;
  border: 1px solid rgb(0, 0, 0) !important;
  width: clamp(250px, 40vw, 400px);

  .flag {
    scale: 1.5;
  }

  .selected-flag:hover {
    background-color: #E4E9ED !important;
    border-radius: 8px !important;
  }

  .input:focus {
    color: #69e781;
  }

  .selected-flag {
    background-color: #E4E9ED !important;
    border-radius: 8px !important;
  }

  .flag-dropdown {
    background: #E4E9ED !important;
    height: 48px !important;
    border: 0 !important;
    border-radius: 8px !important;
  }

  /* dropdown arrow closed */
  .selected-flag .arrow {
    border-left: 5px solid transparent !important;
    border-right: 5px solid transparent !important;
    border-top: 5px solid #252525 !important;
  }

  /* dropdown arrow opened */
  .selected-flag .arrow.up {
    border-top: none !important;
    border-bottom: 5px solid #252525 !important;
  }

  .form-control {
    padding-left: 62px !important;
    height: 48px !important;
    font-size: 1.2rem !important;
    background-color: #E4E9ED !important;
    border: 0 #252525 !important;
    margin-right: 20;
    border-radius: 6px !important;
    width: 100% !important; // Ocuppy all the space available
  }

  .country-list {
    position: absolute !important;
    left: 0 !important;
    right: 0 !important;
  }

  .country-list .country {
    padding: 16px 24px !important;
    min-height: 56px !important;
  }

  .country-list .country:hover {
    background: rgba(97, 115, 138, 0.477) !important;
  }

  .country-list .country.highlight {
    background: rgb(228, 233, 237) !important;
  }

}
