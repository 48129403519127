// UserMainPage

.userMainPageButtonAddress {
  border-width: 1px;
  margin-bottom: 30px;
  width: clamp(150px, 90vw, 500px) !important;
  font-family: Roboto;
  font-size: clamp(12px, 1.5vw, 20px);
  white-space: nowrap;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  &:hover {
    background-color: rgba(169, 182, 125, 0.459);
  }
}

.userMainPageButtonAddAddress {
  border-width: 1px;
  margin-bottom: 30px;
  width: clamp(150px, 40vw, 500px) !important;
  font-family: Roboto;
  font-size: clamp(12px, 1.5vw, 20px);
  white-space: nowrap;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  &:hover {
    background-color: rgba(169, 182, 125, 0.459);
  }
}

.userMainPageResponsiveImageHeight {
  width: clamp(12px, 2vw, 30px);
  aspect-ratio: 1 / 1;
}
