// Navbar
$navbar-text-color: #6c757d;

.navbar {
  background-color: rgb(255, 255, 255);
  position: fixed;
  width: 100%;
  padding: 0;
  z-index: 3;
  flex-shrink: 0; // Prevent navbar from shrinking
}

.nav-item {
  font-size: large;
  font-weight: bold;
}

.nav-link {
  margin-right: 10px;
}

.dropdown-item {
  font-style: italic;
}

.signLogButtons {
  display: flex;
  align-items: right;
}

.overlay-menu {
  position: fixed;
  top: 40px;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 2;
  display: none;
  overflow-y: auto; // Enable scrolling on the overlay if needed

  &.show {
    display: flex;
  }

  .overlay-content {
    padding: 20px;
    ul {
      padding: 0;
      list-style: none;
      li {
        margin-bottom: 10px;
      }
      .nav-link {
        color: #fff;
        font-size: 1.2rem;
        &:hover {
          color: #aaa; // Change color on hover
        }
      }
      .dropdown-item {
        color: #2e2929;
        font-size: 1rem;
      }
    }
  }
}

// Adjustments for smaller screens
@media (max-width: 991.98px) {
  .d-flex {
    flex-wrap: nowrap;
  }

  .navbar-brand{
    flex: 1;
    padding: 0px;
    margin-right: 0px;

  }

  .signLogButtons {
    button {
      font-size: 0.8rem; // Adjust the font size as needed
      margin-left: 0px !important;
      margin-right: 2px;
      padding: 4px 3px; // Adjust the padding as needed
      margin-top: 1px;
      margin-bottom: 1px;
    }
  }


  .navbar-toggler {
    flex: 1;
    padding: 0px;
    width: 30px;
    margin-left: 2px;
  
    &:focus {
      box-shadow: none;
    }
  }

  .navbar-brand {
    img {
      width: 120px;
      margin-left: -5px;
    }
  }

  .navbar-nav {
    display: none; // Hide navbar-nav for smaller screens (personal, business and company)
  }

  .overlay-menu {
    .overlay-content {
      display: flex;
      text-align: left;
      flex-direction: column;
      padding: 20px;
    }
  }
}
