// Footer

.footercss {
  margin-top: 30px;
  width: 100%;
  flex-shrink: 0; // Prevent navbar from shrinking
}


// Adjustments for smaller screens
@media (max-width: 550px) {
  .footercss {
    margin-top: calc(100vh - 350px);
  }
}